<template>
  <section v-if="news.length" id="section-news-lastest" class="social p-3">
    <input v-model="search" type="text" class="form-control" placeholder="Pesquise o título" />
    <router-link v-for="(post, p) in getSearch()" :key="`post-${p}`" :to=" `/news/${post.guid.slice(-7)}`"
      class="row mx-0 mb-2 py-2 rounded bg-white text-decoration-none">
      <div class="col-lg-3"> <img :src="post.avatar" width="100%" height="189" alt=""> </div>
      <div class="col-lg px-lg-0">
        <div class="row mx-0">
          <div class="col px-0"> <strong> {{ post.post_title }} </strong> </div>
        </div>
        <div class="row mx-0">
          <div class="col-auto ms-auto px-2"><small> {{ post.post_date }} </small></div>
        </div>
        <div class="row mx-0">
          <div class="text-secondary ps-0 pe-2"> {{ (post.post_content).slice(0,250) }}...
            <span class="text-primary">ver mais</span> </div>
        </div>
      </div>
    </router-link>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        search: '',
      }
    },
    beforeMount() {
      this.$store.dispatch('getNews', 10000);
    },
    mounted() {
      setTimeout(() => {
        Array.from(document.querySelectorAll('#content img')).map(i => {
          if (i.naturalHeight == 0 && i.naturalWidth == 0) i.src=require('@/assets/SemImagem.jpg')
        });
      }, 1);
    },
    computed: {
      news() {
        return this.$store.state.main.news;
      }
    },
    methods: {
      getSearch() {
        const list = [];
        if (this.search)(this.news).map(post => {
          if ((post.post_title.toLowerCase()) != (post.post_title.toLowerCase()).split(this.search.toLowerCase()))
            list.push(post)
        })
        return (this.search) ? list : this.news
      }
    }
  }
</script>

<style scoped>
  .social {
    height: calc(100vh - 340px);
  }
</style>
